.banner-content {
  background-color: var(--light-blue);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 56px;
  padding-left: 400px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.banner-yeti {
  position: absolute;
  left: 56px;
  bottom: 0;
  height: 100%;
  transform: scale(120%) translateY(-8%);
}

.banner-title {
  color: white;
  margin-bottom: 0;
}

@media all and (max-width: 1200px) {
  .banner-content {
    flex-direction: column;
    align-items: start;
  }
}

@media all and (max-width: 1024px) {
  .banner-content {
    padding-left: 56px;
    align-items: center;
    gap: 40px;
    padding: 40px 24px 0 24px;
  }

  .banner {
    padding-top: 40px;
  }

  .banner-yeti {
    position: static;
    width: 100%;
    transform: unset;
    max-width: 400px;
    aspect-ratio: 400 / 418;
  }

  .banner-title {
    width: 100%;
    text-align: center;
  }
}
