* {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  list-style-type: none;
  border: none;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --light-blue: #1c0e86;
  --dark-blue: #070138;
  --purple: #4d3cbb;
  --white: #f4f3f9;
  --green: #00cfb4;
  --yellow: #ffce00;
}

h1 {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}

h2 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 40px;
  line-height: 64px;
  margin-bottom: 80px;
}

h3 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.hook {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.form-text {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.bold {
  font-weight: 700;
}

.base-container {
  width: 100%;
  box-sizing: border-box;
  padding: 80px 24px;
}

.base-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.primary-button {
  background-color: var(--yellow);
  border-radius: 10px;
  padding: 16px 32px;
  cursor: pointer;
  color: #1c0e86;
  border: solid var(--yellow) 1px;
  font-family: Montserrat;
  box-sizing: border-box;
  font-weight: 600;
  transition: 0.2s;
  width: fit-content;
  height: fit-content;
  display: block;
  font-size: 18px;
}

.secondary-button {
  background-color: var(--light-blue);
  border-radius: 10px;
  padding: 16px 32px;
  cursor: pointer;
  color: #1c0e86;
  border: solid var(--light-blue) 1px;
  transition: 0.2s;
  font-family: Montserrat;
  box-sizing: border-box;
  font-weight: 600;
  color: white;
  width: fit-content;
  height: fit-content;
  display: block;
  font-size: 18px;
  position: relative;
  overflow: hidden;
}

.secondary-button.disabled {
  background-color: #C5C5C5;
  cursor: default;
  border-color: #C5C5C5;
}

.secondary-button.loading {
  background-color: #C5C5C5;
  cursor: default;
  border-color: #C5C5C5;
}

.secondary-button.loading::before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #C5C5C5;
  position: absolute;
  background-image: url(../images/icons/loader.svg);
  background-size: 40px 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.extension-button {
  background-color: var(--yellow);
  display: flex;
  align-items: center;
  gap: 16px;
  border: solid var(--yellow) 1px;
  transition: 0.2s;
  font-family: Montserrat;
  box-sizing: border-box;
  font-weight: 600;
  width: fit-content;
  padding: 8px 32px;
  border-radius: 10px;
  color: var(--light-blue);
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  cursor: default;
}

.extension-button::before {
  background-image: url(../images/chrome-icon.png);
  display: block;
  content: "";
  width: 44px;
  height: 38px;
  background-size: cover;
}

.extension-button:hover {
  background-color: white;
  border-color: var(--light-blue);
}

.primary-button:hover,
.secondary-button:not(.disabled):hover {
  background-color: white;
  border-color: #1c0e86;
  color: var(--light-blue);
}

.anchor {
  /* display: none; */
  /* border: solid; */
}

.forbidden {
  cursor: not-allowed;
}

.forbidden:hover {
  text-decoration: none !important;
}

@media all and (max-width: 768px) {
  .primary-button,
  .secondary-button,
  .extension-button {
    font-size: 16px;
  }

  .primary-button,
  .secondary-button {
    padding: 12px 24px;
  }

  .extension-button {
    padding: 4px 20px;
  }

  h1 {
    font-size: 32px;
    line-height: 42px;
  }

  h2 {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .hook {
    font-size: 16px;
    line-height: 24px;
  }
}
