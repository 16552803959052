.contact-section {
  padding-bottom: 160px;
}

.contact-title {
  color: var(--light-blue);
  width: 100%;
  text-align: center;
}

.contact-box {
  display: flex;
  box-shadow: 0px 0px 10px 0px #1c0e8640;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
}

.contact-form {
  box-sizing: border-box;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.contact-form-line {
  display: flex;
  gap: 48px;
}

.contact-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.contact-input-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--light-blue);
  font-family: Montserrat;
}

.contact-input {
  width: 100%;
  border-bottom: solid #1c0e8680 1px;
  padding-bottom: 8px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s;
  color: var(--light-blue);
}

.contact-input::placeholder {
  color: #1c0e8640;
}

.contact-input:focus {
  outline: none;
  border-bottom-color: var(--light-blue);
}

.contact-input.textarea {
  resize: none;
  width: 100%;
  height: 100px;
  overflow: scroll;
}

.contact-form-checkbox {
  display: none;
}

.contact-form-checkbox-container {
  display: flex;
  cursor: pointer;
  gap: 14px;
  align-items: center;
  margin-top: 192px;
  margin-bottom: 32px;
}

.contact-form-square {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  border: solid var(--light-blue) 1px;
  flex-shrink: 0;
}

.contact-form-conditions-text {
  color: var(--light-blue);
  width: 100%;
  max-width: 650px;
}

.contact-form-checkbox-container:hover
  .contact-form-checkbox:not(:checked)
  ~ .contact-form-square {
  background-color: #1c0e8640;
}

.contact-form-checkbox:checked ~ .contact-form-square {
  background-image: url(../images/icons/chekbox.jpg);
  background-size: contain;
  background-position: center;
}

.contact-pannel {
  border-radius: 20px;
  flex-grow: 1;
  background: linear-gradient(var(--light-blue), var(--dark-blue));
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 56px 24px;
  align-items: center;
  gap: 56px;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  box-sizing: border-box;
}

.contact-pannel-mountains {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  transform: translateY(3px);
}

.contact-pannel-logo {
  width: 150px;
}

.contact-pannel-coord-container {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;
  position: relative;
  z-index: 50;
}

.contact-pannel-coord-item {
  display: flex;
  gap: 14px;
  align-items: center;
}

.contact-pannel-coord-content li {
  font-size: 16px;
  font-weight: 600;
  color: white;
  font-family: Montserrat;
  line-height: 24px;
}

.contact-pannel-networks-container {
  display: flex;
  gap: 24px;
}

.form-error-text {
  color: red;
  display: none;
}

@media all and (max-width: 768px) {
  .contact-section {
    padding-top: 40px;
  }
  
  .contact-box {
    flex-direction: column;
    padding: 0;
  }
  
  .contact-pannel {
    width: 100%;
    max-width: unset;
    padding-bottom: 300px;
    margin-top: 80px;
    overflow: hidden;
    min-width: unset;
  }

  .contact-pannel-mountains {
    width: 100%;
    object-fit: cover;
    transform: translateY(25vw);
  }

  .contact-form {
    padding: 0;
  }

  .contact-box {
    box-shadow: none;
    gap: 0;
  }

  .contact-form-checkbox-container {
    margin-top: 80px;
  }

  .contact-form-line {
    flex-direction: column;
    gap: 24px;
  }
}

@media all and (max-width: 425px) {
  .contact-pannel-mountains {
    transform: translateY(0);
  }
}
