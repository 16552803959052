.demo {
  padding-left: 48px;
  padding-right: 48px;
}

.demo-title {
  color: var(--light-blue);
  width: 100%;
  text-align: center;
  margin-top: 80px;
}

.demo-carousel {
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}

.demo-carousel-arrow {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.demo-carousel-main-image-container {
  flex-grow: 1;
}

.demo-carousel-main-image {
  display: none;
  width: 100%;
  aspect-ratio: 664 / 373 ;
}

.demo-carousel-main-image.visible {
  display: block;
}

.demo-carousel-pagination {
  display: flex;
  margin-top: 24px;
  gap: 12px;
  justify-content: center;
  display: none;
}

.demo-carousel-pagination-circle {
  width: 12px;
  height: 12px;
  background-color: var(--white);
  border-radius: 12px;
  cursor: pointer;
}

.demo-carousel-pagination-circle.selected {
  background-color: var(--light-blue);
}

@media all and (max-width: 768px) {
  .demo-carousel-pagination {
    display: flex;
  }

  .demo-carousel-arrow {
    display: none;
  }

  .demo {
    padding-left: 24px;
    padding-right: 24px;
  }

  .demo-title {
    margin-top: 40px;
  }
}
