.partners-title {
  width: 100%;
  text-align: center;
  color: var(--light-blue);
}

.partners-logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.partners-logo {
  width: 150px;
  height: 109.5px;
}

@media all and (max-width: 768px) {
  .partners-logo {
    width: 100px;
    height: 73px;
  }

  .partners {
    padding-top: 40px;
  }
}
