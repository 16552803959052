.users-section {
  padding-top: 120px;
  padding-bottom: 160px;
}

.users-title {
  color: var(--light-blue);
  text-align: center;
}

.users-carousel {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 24px;
}

.users-carousel-arrow {
  /* width: 48px;
  height: 48px; */
  cursor: pointer;
}

.users-carousel-content {
  flex-grow: 1;
  overflow: scroll;
  display: flex;
  height: 135px;
  gap: 56px;
  scroll-behavior: smooth;
}


.users-carousel-logo {
  width: 150px;
  object-fit: contain;
  flex-shrink: 0;
  box-sizing: border-box;
}

.users-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.users-logo {
  width: 150px;
  object-fit: contain;
  flex-shrink: 0;
  box-sizing: border-box;
}

.users-title {
  width: 100%;
  text-align: center;
}

@media all and (max-width: 768px) {
  .users-carousel-arrow {
    display: none;
  }
  
  .users-section {
    padding-bottom: 120px;
  }
  
  .users-logo {
    width: 100px;
  }

  .users-carousel-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 5px;
  }
  
  .users-carousel-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

@media all and (min-width: 768px) {
  .users-carousel-content::-webkit-scrollbar {
    display: none;
  }
  
  
  .users-carousel-content {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
} 
