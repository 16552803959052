.comments-title {
  color: var(--light-blue);
  width: 100%;
  text-align: center;
}

.comments-carousel-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.comments-carousel-content {
  flex-grow: 1;
  display: flex;
  gap: 24px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.comments-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px #1C0E8640;
  flex-shrink: 0;
}

.comments-item-top {
  display: flex;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  align-items: center;
  position: relative;
}

.comments-item * {
  color: var(--light-blue);
}

.comments-item-picture {
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}

.comments-item-top-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comments-item-bottom {
  box-sizing: border-box;
  padding: 24px;
}

.comments-carousel-arrow {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.comments-carousel-arrow.disabled {
  cursor: default;
  opacity: .5;
}

.comments-item-quote {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  bottom: 0;
  transform: translateY(50%);
}

@media all and (max-width: 768px) {
  .comments-carousel-arrow {
    display: none;
  }

  .comments-carousel-content {
    padding: 10px;
    overflow: scroll;
  }

  .comments-item {
    /* width: calc(100vw - (2 * 24px) - (2 * 10px)); */
    width: 80vw;
  }
}
