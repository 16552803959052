header {
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: var(--light-blue);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.header-logo {
  width: 150px;
}

nav {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-button {
  color: white;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  font-family: Montserrat;
  cursor: pointer;
}

.nav-button:hover {
  text-decoration: underline;
}

.nav-button.yellow {
  color: var(--yellow);
}

.nav-dot {
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 2px;
}

.m-menu-checkbox {
  display: none;
}

.m-menu-label {
  width: 18px;
  height: 14px;
  cursor: pointer;
  position: relative;
  display: none;
}

.burger-icon {
  width: 100%;
  height: 100%;
}

.burger-bar {
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background-color: white;
  transition: .2s;
  position: absolute;
}

.burger-bar:nth-child(1) {
  top: 0;
  left: 0;
}

.burger-bar:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger-bar:nth-child(3) {
  bottom: 0;
  left: 0;
}

.m-menu-checkbox:checked ~ .m-menu {
  transform: translateX(0);
}

header:has(~ .m-menu-checkbox:checked) .m-menu-label .burger-bar:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

header:has(~ .m-menu-checkbox:checked) .m-menu-label .burger-bar:nth-child(2) {
  opacity: 0;
}

header:has(~ .m-menu-checkbox:checked) .m-menu-label .burger-bar:nth-child(3) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.m-menu {
  width: 100%;
  box-sizing: border-box;
  margin-top: 80px;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 80px;
  justify-content: space-between;
  align-items: center;
  display: none;
  transition: .5s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  transform: translateX(100%);
  background: linear-gradient(var(--light-blue), var(--dark-blue));
  z-index: 500;
}

.m-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.m-nav-button {
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  cursor: pointer;
}

.m-nav-button:hover {
  text-decoration: underline;
}

.m-nav-button.yellow {
  color: var(--yellow);
}

@media all and (max-width: 1200px) {
  .header-cta, nav {
    display: none;
  }

  .m-menu {
    display: flex;
  }

  .header-logo {
    width: 100px;
  }

  .m-menu-label {
    display: block;
  }

  header {
    height: 78px;
  }

  .header-content {
    height: 100%;
  }

  .header-logo-button {
    height: 100%;
  }

  .header-logo {
    height: 100%;
    width: auto;
  }

  .m-menu {
    margin-top: 78px;
    height: calc(100vh - 78px);
  }
}
