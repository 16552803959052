.mentions {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 600;
  display: none;
}

#mentions-checkbox:checked ~ .mentions {
  display: block;
}

.mentions-background {
  background-color: #f4f3f9;
  opacity: .95;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mentions-content {
  border: solid var(--light-blue) 1px;
  position: relative;
  z-index: 700;
  box-sizing: border-box;
  padding: 80px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.mentions-title {
  color: var(--light-blue);
  margin-bottom: 40px;
}

.mentions-subtitle {
  color: var(--light-blue);
  margin-bottom: 24px;
}

.mentions-subtitle:not(:first-child) {
  margin-top: 32px;
}

.mentions-last-update {
  margin-top: 32px;
}

.mentions-text {
  color: var(--light-blue);
  margin-bottom: 8px;
}

.mentions-checkbox {
  display: none;
}

.mentions-close {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 28px;
  right: 20px;
}

.mentions-close-bar {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: var(--light-blue);
  position: absolute;
}

.mentions-close-bar:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.mentions-close-bar:nth-child(2) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

@media all and (max-width: 768px) {
  .mentions-content {
    padding: 16px;
  }

  .mentions-close {
    width: 24px;
    height: 24px;
  }
} 
