footer {
  background-color: var(--light-blue);
  padding-top: 64px;
  padding-bottom: 64px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-top {
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  width: 150px;
}

.footer-title {
  color: white;
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  border-top: solid white 1px;
  border-bottom: solid white 1px;
  align-items: center;
}

.footer-navigation-title {
  text-transform: uppercase;
  color: white;
  text-align: center;
}

.footer-navigation-links-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 40px;
}

.footer-navigation-link {
  font-family: Montserrat;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.footer-navigation-link:hover {
  text-decoration: underline;
}

.footer-navigation-dot {
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 2px;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.footer-bottom-link {
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;
}

.footer-bottom-link:hover {
  text-decoration: underline;
}

.footer-bottom-copyrights {
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

@media all and (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    gap: 24px;
  }

  .footer-content {
    gap: 40px;
  }

  .footer-navigation-links-container {
    flex-direction: column;
    margin-top: 32px;
  }

  .footer-bottom-links {
    flex-direction: column;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 80px;
  }
}
