.hero-section {
  background: linear-gradient(var(--light-blue), var(--dark-blue));
  position: relative;
  overflow: hidden;
  margin-top: 82px;
}

.hero-content {
  width: 50%;
}

.hero-base-content {
  position: relative;
  padding-bottom: 350px;
  overflow: hidden;
}

.hero-title {
  color: white;
}

.hero-title .yellow {
   color: var(--yellow);
}

.hero-hook {
  color: white;
  margin-top: 40px;
}

.hero-cta {
  margin-top: 80px;
  position: relative;
  z-index: 100;
}

.hero-yeti {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 600px;
  z-index: 20;
}

.hero-wave {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 300px;
  z-index: 10;
  object-position: right;
  object-fit: cover;
}

@media  all and (max-width: 1450px) {
  .hero-wave {
    width: 120%;
  }
}

@media  all and (max-width: 1200px) {
  .hero-yeti {
    right: 0px;
    width: 500px;
  }

  .hero-wave {
    width: 140%;
  }
}

@media all and (max-width: 1200px) {
  .hero-section {
    margin-top: 78px;
  }
}

@media all and (max-width: 900px) {
  .hero-yeti {
    position: static;
    width: 100%;
    max-width: 400px;
    margin-top: 40px;
  }

  .hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-content {
    width: 100%;
  }

  .hero-wave {
    width: 200%;
  }

  .hero-base-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
}

@media all and (max-width: 768px) {
  .hero-section {
    padding-top: 24px;
    padding-bottom: 0;
  }

  .hero-cta {
    margin-top: 40px;
  }
} 
