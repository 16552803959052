.commitments {
  background-color: #1C0E860D;
}

.commitments-title {
  color: var(--light-blue);
  width: 100%;
  max-width: 564px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.commitments-dropdowns-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.commitments-dropdown {
  display: flex;
  box-sizing: border-box;
  padding: 40px;
  border-radius: 20px;
  width: 100%;
  background-color: white;
  align-items: center;
  gap: 40px;
  cursor: pointer;
}

.commitments-dropdown:hover {
  box-shadow: 0px 0px 10px 0px #1C0E8640;
}

.commitments-icon {
  width: 100px;
  flex-shrink: 0;
}

.commitments-dropdown-content {
  flex-grow: 1;
}

.commitments-dropdown-title {
  color: var(--light-blue);
}

.commitments-dropdown-intro {
  margin-top: 16px;
  color: var(--light-blue);
}

.commitments-dropdown-checkbox {
  display: none;
}

.commitments-dropdown-body {
  display: none;
  flex-direction: column;
  border-left: solid var(--light-blue) 1px;
  margin-top: 16px;
  box-sizing: border-box;
  padding-left: 16px;
  gap: 24px;
}

.commitments-dropdown-body-part-title {
  font-family: Montserrat;
  color: var(--light-blue);
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
}

.commitments-dropdown.visible .commitments-dropdown-body {
  display: flex;
}

.commitments-dropdown.visible {
  box-shadow: 0px 0px 10px 0px #1C0E8640;
}

.commitments-chevron {
  width: 16px;
  height: 8px;
  transition: .2s;
}

.commitments-dropdown-text {
  color: var(--light-blue);
}

.commitments-dropdown.visible .commitments-chevron {
  transform: rotate(180deg);
}

.commitments-dropdown-list li {
  color: var(--light-blue);
  font-family: Montserrat;
  align-items: start;
  line-height: 24px;
  font-size: 16px;
}

.commitments-dropdown-list li.red {
  color: #DB4437;
}

.commitments-dropdown-list li {
  list-style-type: disc;
  margin-left: 16px;
}

.commitments-cta {
  margin: 80px auto 0 auto;
}

@media all and (max-width: 768px) {
  .commitments-dropdown {
    flex-direction: column;
  }

  .commitments-icon {
    width: 80px;
  }

  .commitments-dropdown-title {
    text-align: center;
  }

  .commitments-dropdown:nth-child(1) .commitments-icon {
    height: 81.73px;
  }

  .commitments-dropdown:nth-child(2) .commitments-icon {
    height: 73px;
  }

  .commitments-dropdown:nth-child(3) .commitments-icon {
    height: 128px;
  }

  .commitments-dropdown:nth-child(4) .commitments-icon {
    height: 75.19px;
  }

  .commitments-dropdown:nth-child(5) .commitments-icon {
    height: 93.16px;
  }

  .commitments-dropdown:nth-child(6) .commitments-icon {
    height: 78px;
  }
}
